import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '@openmotics/react-sdk/api';
import InputField from '../../components/input-field/input-field';
import Button from '../../components/button/Button';
import PrimaryButton from '../../components/button/PrimaryButton';
import PasswordField from '../../components/password-field/password-field';
import LoginContainer from '../../components/login-container/login-container';
import { Storage } from '../../../src/components/storage';
import TEST_IDS from './test-ids';
import styles from './login-page.module.scss';
import Splash from './splash';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import shared from '../../../src/components/shared';

const initialState = {
  username: '',
  password: '',
  totp: undefined,
};

const errorMap = {
  TOTP_INVALID: 'login.form.totpIncorrect',
  INVALID_CREDENTIALS: 'login.form.credentialsIncorrect',
};

interface Props {
  onLoggedIn: (token: string) => Promise<any>;
}

// Fix MS junk
const interactionStatus = sessionStorage.getItem('msal.interaction.status');
if (interactionStatus === 'undefined') {
  sessionStorage.removeItem('msal.interaction.status');
}

const LoginPage = ({ onLoggedIn }: Props) => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();

  const [state, setState] = useState(initialState);
  const [signInWithOpenmotics, setSignInWithOpenmotics] = useState<boolean | null>(null);
  const [show2Fa, setShow2Fa] = useState(false);
  const [login, { data }] = useLoginMutation();

  useEffect(() => {
    if (data && data.data?.token) {
      Storage.setItem('token', data.data.token);
      onLoggedIn(data.data.token);
    }

    if (data && data.data?.nextStep === 'TOTP_REQUIRED') {
      setShow2Fa(true);
    }
  }, [data]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (instance.getAllAccounts().length > 0) {
        instance
          .acquireTokenSilent(authRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            const accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            Storage.setItem('token', accessToken);
            onLoggedIn(accessToken);
          });
      }
    }

  }, [inProgress, instance]);

  const onChange = (value: any, fieldName: string) => {
    setState({
      ...state,
      [fieldName]: value,
    });
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    login(state);
  };


  const authRequest = {
    scopes: ['offline_access', 'openid', shared.settings.clientId],
  };
  const signInWithRenson = () => {
    instance.loginPopup(authRequest)
      .then((resp) => {
        const token = resp.accessToken;
        Storage.setItem('token', token);
        onLoggedIn(token);
        // Set the account as active
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let errorMessage = null;
  if (data && data.error) {
    // @ts-ignore
    errorMessage = <span className={styles.error}>{t(errorMap[data.errorCode])}</span>;
  }

  const LoginText = <span />;

  // show a selection screen
  if (!signInWithOpenmotics) {
    return <Splash
      onClickOpenmotics={() => setSignInWithOpenmotics(true)}
      onClickRenson={signInWithRenson}
    />;
  }

  return (
    <LoginContainer text={LoginText} title={t('login.welcome')} subTitle={t('login.signInSession')}>
      <form className={styles.form} onSubmit={onSubmit} data-testid={TEST_IDS.loginForm}>
        {!show2Fa && (
          <>
            <InputField id="username" placeholder={t('login.username')} onChange={onChange} value={state.username} />
            <PasswordField id="password" placeholder={t('login.password')} onChange={onChange} value={state.password} />
          </>
        )}

        {show2Fa && (
          <InputField id="totp" placeholder={t('login.form.totp')} onChange={onChange} value={state.totp ?? ''} />
        )}

        {errorMessage}

        <PrimaryButton label={t('login.signIn')} type="submit" />
        { show2Fa &&
          <Button label={t('general.cancel')} type="button" onClick={() => setShow2Fa(false)} />
        }
        <div className={styles.forgotten}><a href="#/forget-password">{t('login.reset')}</a></div>
      </form>
    </LoginContainer>
  );
};

export default LoginPage;
